import React from "react"
import { graphql } from "gatsby"
import BlogPostChrome from "../../components/BlogPostChrome"
import Img from 'gatsby-image'
import { Avatar, List, Row, Col, Spin, Tooltip, Input, Button, Modal } from 'antd';
import './First.css'
import axios from 'axios';
import Layout from './../../components/Layouts';

export const frontmatter = {
  title: `First Post About First Post`,
  written: `2017-12-20`,
  updated: `2017-12-20`,
  layoutType: `post`,
  contentType: `blog`,
  path: `/a-first-post/`,
  category: `Beginnings`,
  image: `./poster.jpg`,
  description: `Hello world`
}

const API = axios.create({
  baseURL: 'https://api.sprinkleai.com'
  // baseURL: 'http://192.168.1.105:9060'
});


const helloMessage = `Hello there, I am CUBOT. I am a generative chatbot, meaning I have learned to chat (or generate replies)
by looking at movie dialogues.`;


const BotInfo = () => {
  return (
    <div className="botinfo">
        Built using <a href="https://google.github.io/seq2seq/" target="_blank" rel="noopener noreferrer">seq2seq</a> model, trained on <a href="https://www.cs.cornell.edu/~cristian/Cornell_Movie-Dialogs_Corpus.html" target="_blank" rel="noopener noreferrer">cornell corpus </a>
    </div>
  )
}

class HelloWorldArticle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [{
        icon: 'cubot',
        actor: 'cubot', 
        text: helloMessage
      }],
      // data: [`CUBOT: ${helloMessage}`],
      chatVisible: false,
      cubot: this.props.data.cubot.childImageSharp.resolutions
    }  
  }

  //scroll: https://stackoverflow.com/questions/37620694/how-to-scroll-to-bottom-in-react
  scrollToBottom = () => {    
    if (this.messagesEnd)  {
      // console.log('scrolling down');
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
      // this.messagesEnd.scrollIntoView();
    }
  }
  
  // scrollToBottom() {
  //   if (this.messagesEnd)  {
  //     const scrollHeight = this.messagesEnd.scrollHeight;
  //     const height = this.messagesEnd.clientHeight;
  //     const maxScrollTop = scrollHeight - height;
  //     this.messagesEnd.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  //   }
  // }

  componentDidMount() {
    this.scrollToBottom();
  }
  
  componentDidUpdate() {
    this.scrollToBottom();
  }  

  setChatVisible(chatVisible) {
    this.setState({chatVisible});
  }

  sendMessage(e) {
    const message = e.target.value;
    if (!message || message.trim().length == 0) {
      return;
    }

    let messageObject = {
      icon: 'user',
      actor: 'YOU',
      text: `${message}`
    };
    let messages = this.state.data;
    messages.push(messageObject);
    this.setState({
      data: messages
    });
    e.target.value = '';

    var formdata = new FormData();
    formdata.set('message', message);
    // API.post(`/chat`, formdata)
    API.post(`/neuralbot/chat`, formdata, {crossdomain: true})
        .then(response => {
            // console.log(`response: ${JSON.stringify(response.data)}`);
            if (response.data) {
              const messageObject = {
                icon: 'cubot',
                actor: 'cubot',
                text: `${response.data}`
              };
              let messages = this.state.data;
              messages.push(messageObject);
              this.setState({
                loading: false,
                data: messages
              });
            }
        })
        .catch(error => {
            console.log(`Failed to get response from server: ${error}`);
            this.setState({loading: false});
        });    
  }

  avatar = (name) => {
    let element;
    if (name === 'cubot') {
      element = <Avatar src={this.props.data.cubot_k.childImageSharp.resolutions.src} />
    } else {
      element = <Avatar icon='user' />
    }

    return element;
  }


  showCubot = () => {
    this.setState({
      cubot: this.props.data.cubot.childImageSharp.resolutions
    })
  }

  showCubotK = () => {
    this.setState({
      cubot: this.props.data.cubot_k.childImageSharp.resolutions
    })
  }

  render() {
    const { loading, data, cubot } = this.state;
    // console.log(`data: ${JSON.stringify(data)}`);
    // console.log(`cubot: ${JSON.stringify(this.state.cubot)}`);

    // const cubot = this.state.cubot;
    // const cubot = this.props.data.cubot.childImageSharp.resolutions
    return (
      <Layout data={this.props.data} location={this.props.location}>
      <BlogPostChrome {...this.props.data.javascriptFrontmatter}>
        <article>
        <div style={{textAlign: "center", margin: "0 auto"}}>
          <p>
            With this section, I intend to document applied machine learning 
            research along with interactive examples where possible.
            </p>
          <h1 className="hellow">Hello World,</h1>        
          {/* <Row type="flex" justify="center"> */}
          <Row>
            <Col span={24}  onMouseEnter={this.showCubotK} onMouseLeave={this.showCubot}> 
              <Tooltip title="Hi there, I am CUBOT. Click on my image to chat with me">
                <div onClick={() => this.setChatVisible(true)} style={{cursor: 'pointer'}} className="cubotthumbnail">
                  <Img resolutions={cubot} className="cubotthumbnail"/>
                </div>
                </Tooltip>                            
            </Col>            
          </Row>  
          </div>
          <div style={{textAlign: "center", margin: "0 auto"}}>
          <Modal centered 
            title="CHAT" visible={this.state.chatVisible} 
            onCancel={() => this.setChatVisible(false)} 
            okText="Close" cancelButtonDisabled 
            footer={<BotInfo />}>
            <div className="chatmessages">
              <List                 
                loading = {loading}
                itemLayout="horizontal" 
                dataSource={data} 
                renderItem = {item => (
                    <List.Item>
                      <List.Item.Meta 
                        avatar={this.avatar(item.icon)}
                        title = {item.actor}
                        description = {item.text}
                      />
                    </List.Item>
                )}>

              </List>
              <div style={{ float:"left", clear: "both", marginTop: 80 }}
               ref={(el) => { this.messagesEnd = el; }}>
              </div>              
            </div>
            {/* <TextArea rows={4} value={data}/> */}
            <div>
              <Input placeholder="Hit enter to send" onPressEnter={this.sendMessage.bind(this)}/>              
            </div>
          </Modal>
          </div>

        </article>
      </BlogPostChrome>
      </Layout>
    )
  }
}

export default HelloWorldArticle

// We want to keep this component mostly about the code
//  so we write our explanation with markdown and manually pull it in here.
//  Within the config, we loop all of the markdown and createPages. However,
//  it will ignore any files appended with an _underscore. We can still manually
//  query for it here, and get the transformed html though because remark transforms
//  any markdown based node.
export const pageQuery = graphql`
    query helloworldquery($slug: String!) {
      markdownRemark(
        fields: { slug: { eq: "/2017-01-22-a-first-post/" } }
      ) {
        html
      }
      javascriptFrontmatter(fields: { slug: { eq: $slug } }) {
        ...JSBlogPost_data
      }
      cubot: file(
        relativePath: {regex: "/cubot_500.png/"}) {
        childImageSharp {
          resolutions(width: 500, height: 439) {
            ...GatsbyImageSharpResolutions
          }
        }
      }    
      cubot_k: file(
        relativePath: {regex: "/cubot_k500.png/"}) {
        childImageSharp {
          resolutions(width: 500, height: 439) {
            ...GatsbyImageSharpResolutions
          }
        }
      }    
    }
  `
